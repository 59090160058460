import _ from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { useTranslation } from 'ev-i18n';
import {
  Id,
  Practice,
  SignInUser,
  SupportedUserLanguages,
  User,
  UserPreference,
  VisitParticipant,
} from 'ev-types';

import { UserResponseData } from 'ev-api/core';
import { EVColors } from 'ev-theme/styles';

type RoleCheckParameters = {
  user: User | VisitParticipant | undefined;
  practiceId: Id | undefined;
};

export const isPatient = (user: User | SignInUser | undefined) => {
  return user?.attributes.role === 'patient';
};

export const isAttendee = (user: User | undefined) => {
  return user?.attributes.role === 'attendee';
};

export const isPatientOrAttendee = (user: User | undefined) => {
  return isPatient(user) || isAttendee(user);
};

export const isAdminManagerOrOwner = (user: User, practice: Practice) => {
  return (
    isAdmin({ user: user, practiceId: practice.id }) ||
    isVisitManager({ user: user, practiceId: practice.id }) ||
    isOwner({ user: user, practiceId: practice.id })
  );
};

export const isGuarantorOfDependent = (
  guarantor: User | undefined,
  dependentId: Id,
): boolean => {
  if (!guarantor) {
    return false;
  }
  const dependentIds = guarantor.dependents.map(dep => dep.id);
  return dependentIds.includes(dependentId);
};

export const isDependentOfId = (dependent: User, guarantorId: Id): boolean => {
  return (
    dependent.attributes.is_dependent &&
    dependent.attributes.creator?.user_id === guarantorId
  );
};

function hasRole({
  user,
  role,
  practiceId,
}: {
  user: User | VisitParticipant | undefined;
  role: string;
  practiceId: Id | undefined;
}): boolean {
  return (
    user?.attributes.role === role ||
    _.some(
      user?.attributes.roles,
      ({ role: userRole, practice_id }) =>
        userRole === role && practice_id === practiceId,
    )
  );
}

export const isProvider = ({ user, practiceId }: RoleCheckParameters) => {
  return (
    hasRole({ user, practiceId, role: 'physician' }) ||
    hasRole({ user, practiceId, role: 'staff' })
  );
};

export const isAdmin = ({
  user,
  practiceId,
}: {
  user: User;
  practiceId?: Id;
}) => {
  const customerRolesEnabled = getUserPreference(
    user,
    UserPreference.CustomerRoles,
  );
  if (customerRolesEnabled) {
    return !!user.customer_role?.access_admin;
  } else {
    return hasRole({ user, practiceId, role: 'admin' });
  }
};

export const isOwner = ({
  user,
  practiceId,
}: {
  user?: User;
  practiceId?: Id;
}) => {
  const customerRolesEnabled = getUserPreference(
    user,
    UserPreference.CustomerRoles,
  );
  if (customerRolesEnabled) {
    return user?.customer_role?.name === 'owner';
  } else {
    return hasRole({ user, practiceId, role: 'owner' });
  }
};

export const isVisitManager = ({
  user,
  practiceId,
}: {
  user?: User;
  practiceId?: Id;
}) => {
  const customerRolesEnabled = getUserPreference(
    user,
    UserPreference.CustomerRoles,
  );
  if (customerRolesEnabled) {
    return !!user?.customer_role?.manage_staff_visits;
  } else {
    return hasRole({ user, practiceId, role: 'visit_manager' });
  }
};

export const isScheduler = ({
  user,
  practiceId,
}: {
  user?: User;
  practiceId: Id;
}) => {
  const customerRolesEnabled = getUserPreference(
    user,
    UserPreference.CustomerRoles,
  );
  if (customerRolesEnabled) {
    return !!user?.customer_role?.create_staff_visits;
  } else {
    return hasRole({ user, practiceId, role: 'scheduler' });
  }
};

export const isAdvocate = ({ user, practiceId }: RoleCheckParameters) => {
  return hasRole({ user, practiceId, role: 'advocate' });
};

export const isMember = ({
  user,
  practiceId,
}: {
  user: User;
  practiceId: Id;
}) => {
  const customerRolesEnabled = getUserPreference(
    user,
    UserPreference.CustomerRoles,
  );
  if (customerRolesEnabled) {
    return (
      !!user.customer_role?.access_app &&
      user.attributes.practice_ids.includes(practiceId)
    );
  }
  return hasRole({ user, practiceId, role: 'member' });
};

export function getUserPreference(
  user: User | undefined,
  preference: UserPreference,
) {
  const pref = _.find(user?.attributes.preferences, {
    name: preference,
  });

  if (pref) {
    return pref.value;
  }

  return null;
}

export function getUserInitials({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) {
  // We filter the parts array in case there is an empty string
  const parts = [firstName, lastName]
    .filter(str => !!str)
    .map(str => str.trim());
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`;
  } else if (parts.length === 1) {
    return parts[0][0];
  }
  return '';
}
export function getCurrentUserLocale(user?: User) {
  const locale = user?.attributes.locale;
  return getCleanedUserLocale(locale);
}

export function getCleanedUserLocale(locale?: SupportedUserLanguages) {
  return (locale || 'en').split('_')[0] || '';
}

export function getUserCompleteName(user: User | UserResponseData) {
  let { prefix, suffix } = user.attributes;
  const { full_name } = user.attributes;

  prefix = (prefix || '').trim().length > 0 ? `${prefix} ` : '';
  suffix = (suffix || '').trim().length > 0 ? `, ${suffix}` : '';

  return [prefix, full_name, suffix].join('');
}

export const useDobToAge = () => {
  const { t } = useTranslation();

  return useCallback(
    (date: DateTime): string => {
      const diff = DateTime.now()
        .diff(date, ['years', 'months', 'days'])
        .toObject();

      const years = diff.years || 0;
      const months = diff.months || 0;
      const days = Math.floor(diff.days || 0);

      if (years >= 1) {
        return t('{{years}} yrs', { years, count: years });
      } else if (months >= 1) {
        return t('{{months}} months', { months, count: months });
      } else {
        return t('{{days}} days', { days, count: days });
      }
    },
    [t],
  );
};

export const getUserStatusTextColor = (profileIsValid: boolean) =>
  profileIsValid ? EVColors.darkGreen : EVColors.darkRed;

export const getUserStatusBackgroundColor = (profileIsValid: boolean) =>
  profileIsValid ? EVColors.mint : EVColors.sakura;

export const useUserGetStatusText = () => {
  const { t } = useTranslation();
  return useCallback(
    (profileIsValid: boolean) =>
      profileIsValid ? t('REGISTERED') : t('PENDING'),
    [t],
  );
};

export const hasName = (firstName: string, lastName: string) =>
  firstName && lastName;

export const buildUserName = (
  firstName: string,
  lastName: string,
  suffix: string | null,
  lastNameFirst: boolean = false,
) =>
  lastNameFirst
    ? suffix
      ? `${lastName}, ${firstName}, ${suffix}`
      : `${lastName}, ${firstName}`
    : suffix
      ? `${firstName} ${lastName}, ${suffix}`
      : `${firstName} ${lastName}`;
