import { Id } from 'ev-types';

export namespace Qtc {
  export enum Gender {
    Male = 'male',
    Female = 'female',
    NoPreference = 'no_preference',
  }

  export type Address = {
    city: string;
    postal_code: string;
    state: string;
    street: string;
    type: string;
  };

  export type Examinee = {
    id: Id;
    first_name?: string;
    last_name?: string;
    name?: string;
    addresses?: Address[];
  };

  export type Exam = {
    id: Id;
    service_id: string;
    service_name: string;
    status: 'pending' | 'scheduled' | null;
    duration: number;
    due_date: string;
  };

  export type Rule = {
    rule: string;
    value: string;
    with_reason?: boolean;
  };

  export type Appointment = {
    appointment_type_id?: Id;
    appointment_id?: Id;
    name: string;
    duration: number;
    appointment_window: string;
    scheduled: {
      id?: Id;
      date: string;
      location: string;
      address?: string;
      can_reschedule: boolean;
      reschedule_limit?: number;
      state_name?: string;
      physician: {
        first_name: string;
        last_name: string;
      };
    } | null;
    exams: Exam[];
    rules: Rule[];
    procedures?: string[];
    telehealth?: boolean;
  };

  export type Provider = {
    id: Id;
    first_name: string;
    last_name: string;
    email?: string;
    phone?: string;
    address_line_1?: string;
    address_line_2?: string;
    city?: string;
    state?: string;
    zip?: string;
    latitude?: string;
    longitude?: string;
    npi: string;
    service_type_codes: string[];
    licensed_states: string[];
    evisit_provider_uuid?: string;
    mqap_id?: string;
    provider_import_id?: Id | null;
    gender?: Gender | null;
    location_name?: string | null;
    available_slots?: Slot[];
  };

  export type Slot = {
    id: Id;
    provider_id: Id;
    start_datetime: string;
    end_datetime: string;
    available: boolean;
  };

  export type SlotGroup = {
    [providerId: string]: Slot[][];
  };

  export type ProvidersGroupedByDate = {
    date: string;
    providers: Provider[];
  };

  export type ProvidersGroupedByLocation = {
    [location: string]: ProvidersGroupedByDate[] | undefined;
  };

  export enum ConfirmAppointmentOverride {
    Holiday,
    SchedulingWindow,
    RadiusLimit,
  }
}
